
.with-slider {
    .slick-slider {
        margin: 10px 0;
        .slick-list {
    
            .slick-track{
                margin-left: 0;
                margin-right: 0;
                display: flex;
                align-items: stretch;
               
            }
            .slick-slide {
                opacity: 0.5;
                height: auto;
                > div {
                    height: 100%;
                }
                &.slick-active {
                    opacity: 1;
                }
                
            }

            @media only screen and (max-width:420px) {
                overflow: visible;
            }
        }
    }
}

.with-slider-fixed-width {
    .slick-slider {
        margin: 10px 0;
        .slick-list {
    
            .slick-track{
                margin-left: 0;
                margin-right: 0;
                display: flex;
                align-items: stretch;
                
            }
            .slick-slide {
                
                height: auto;
                
                > div {
                    height: 100%;
                }
                &.slick-active {
                    opacity: 1;
                }
                
            }

            @media only screen and (max-width:420px) {
                overflow: visible;
            }
        }
    }
}
