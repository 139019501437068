.content-section{
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @media only screen and (max-width: 855px) {
        flex-direction: column;
    }

    .background-wrapper{
        width: 500px; 
        height: 500px;
    
        background-color: #D9D9D9;
        border-radius: 8px;

        flex: 1;
    
        overflow: hidden;

        @media only screen and (max-width: 960px) {
            width: 400px;
            height: 400px;
        }

        @media only screen and (max-width: 855px) {
            margin: 0 auto;
        }

        @media only screen and (max-width:720px) {
            width: 330px;
        }
    }

    .background-wrapper img{
        width: 100%;
        height: 100%;
        object-fit: cover;

        aspect-ratio: 1/1;
    }
    
    .preview-iframe {
    
        width: 100%;
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.9);
        .preview-expanded-16-9 {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            overflow: hidden;
            display: block;
            right: 0;
            padding: 5vw;
        }
    
    }

}

.content-section-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;

    flex: 1.2;

    .master-ctas {
        display: flex;
        flex-direction: row;

        button{
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 8px;
        }

        @media only screen and (max-width: 720px) {
            flex-direction: column;

            button{
                margin-left: 0;
            }
        }
    }
}

.master-related {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    padding-top: 2rem;

    .related-items {
        display: flex;
        flex-wrap: wrap;
        margin-left: -5px;
        .related-item {
            flex: 1;

            align-content: center;

            background-color: rgba(48, 66, 90, 0.1);
            padding: 10px;
            color: rgba(48, 66, 90, 0.6);
            font-size: 14px;
            margin: 5px;
            border-radius: 8px;
            font-family: PoppinsRegular;
        }
    }
    @media only screen and (max-width:720px) {
        margin-top: 20px;
     }
}

.master-targets {
    padding: 20px 0;
    padding-top: 3rem;
    .targets-wrapper {
        display: flex;
        width: 100%;
        
        p { 
            font-size: medium;
        }
    }
}