.p-wrapper {

    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;

    .inner {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .p-flex {
            flex: 1;//parent with min-height no inherit height to child: fix here: https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height/8468131
            display: flex;
            padding: 5vw 30px;
            align-items: stretch;
            .p-aside {
                flex-grow: 0;
                flex-shrink: 0;
                width: var(--profile-menu-width);
                padding: 10px;
            
                @media only screen and (max-width: 920px) {
                    display: none;
            
                }
                
            }

            .p-content {
                flex-grow: 1;
                flex-basis: auto;
                flex-shrink: 1;
                padding-left: 3vw;
                .a-wrapper {
                    display: flex;
                    justify-content: space-between;
                    .a-f-wrapper {
                        max-width: 600px;
                        flex-basis: 50%;
                        @media only screen and (max-width: 1130px) {
                            max-width: 100%;
                            flex-basis: 100%;
                            margin: 0 0 20px 0;
                        }
                    }
                    .a-p-wrapper {
                       
                        flex-basis: 40%;
                    }
                    @media only screen and (max-width: 1130px) {
                        flex-direction: column;
                
                    }
                }
                .r-a-wrap {
                    padding-top: 40px;
                }
                
                @media only screen and (max-width: 920px) {
                    padding-left: 0;
            
                }

            }

        }

    }
}



//info block suscription


.info-block {

    display: flex;
    margin-bottom: 10px;
    
    .info-item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        padding: 10px 30px;
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        font-family: BebasNeueBold;
        position: relative;
        .info-title {
            color: var(--y-grey3);
            font-size: 29px;
            margin: 0;
            line-height: 29px;
            @media only screen and (max-width: 1200px) {
                font-size: 20px;
                line-height: 20px;
             }
            //  @media only screen and (max-width: 820px) {
            //     font-size: 18px;
            //     line-height: 18px;
            //  }
            
        }
        .info-subtitle {
            color: var(--y-green1);
            font-size: 55px;
            line-height: 55px;
            margin: 0;
            @media only screen and (max-width: 1200px) {
                font-size: 36px;
                line-height: 36px;
             }
            //  @media only screen and (max-width: 820px) {
            //     font-size: 26px;
            //     line-height: 26px;
            //  }
        }
        &.high-color {
            background-color: var(--y-grey18);

        }

        &:last-child{
            &:after{
                content: none;
            }
        }

        &:after{
            content: "";
            position: absolute;
            height: 45%;
            width: 3px;
            background-color: var(--y-grey14);
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transform-origin: center center;
            will-change: transform;
        }

        @media only screen and (max-width: 820px) {
            padding: 10px;
         }

         @media only screen and (max-width: 767px) {
            text-align: left;
            padding: 10px 0px;
            &:after{
                content: none!important;
            }
         }
         
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column-reverse;
        margin-bottom: 15px;
     }
}

.welcome-premium {
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 16px;
    }

    h2 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
        margin-top: 10px;
    }
}

//profile plans section

.plans-grid {

    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .item {
        flex-shrink: 1;
        flex-grow: 0;
        flex-basis: 48%;
        position: relative;
        display: flex;
        flex-direction: column;
        .title {
            font-family: PoppinsBold;
            font-size: 26px;
            color: var(--y-grey3);
            padding: 20px;
            @media only screen and (max-width:590px) {
                padding: 20px 0;
            }
            @media only screen and (max-width:420px) {
                font-size: 16px;
            }
        }
        .floating-cta {
            position: sticky;
            top:0;
            max-width: 400px;
            width: 100%;
            padding: 20px 20px 10px;
            text-align: center;
            align-self: center;
            .title-cta {
                font-family: PoppinsBold;
                font-size: 14px;
                margin: 0;
            }
            .cta-plans {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
                margin-bottom: 10px;
                .plan {
                    flex-shrink: 1;
                    flex-grow: 0;
                    flex-basis: 48%;
                    border-radius: 10px;
                    border:solid 2px #979797;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    position: relative;
                    
                    .checkbox {
                        font-size: 10px!important;
                    }
                    &.active {
                        border:solid 2px var(--y-green1)
                    }
                    span {
                        font-size: 14px;
                        font-family: PoppinsRegular;
                        display: inline-block;
                        margin: 5px 0;
                    }
                    &:first-child {
                        @media only screen and (max-width:420px) {
                            margin-bottom: 20px;
                        }
                    }
                    &.ov-hidden {
                        overflow: hidden;
                    }
                   &.with-ribbon {
                       position: relative;
                       &:after {
                            content: attr(data-name);
                            position: absolute;
                            top: -10px;
                            width: 90px;
                            left: calc(50% - ( (90px + 20px)/2) );
                            background-color: white;
                            color: var(--y-grey3);
                            padding: 0 10px;
                            font-family: PoppinsRegular;
                            box-sizing: content-box;
                            text-transform: uppercase;
                            font-size: 12px;
                       }
                   }
                   .offer-corner {
                   
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-style: solid;
                        border-color: var(--y-green1) transparent transparent var(--y-green1);
                        height: 0;
                        width: 0;
                        border-width: 80px 80px 0px 0px;
                        .text {
                            color: #eee;
                            font-size: 12px;
                            position: relative;
                            left: 6.1px;
                            top: -54.4px;
                            white-space: nowrap;
                            -webkit-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            width: 15px;
                            text-align: center;
                            line-height: 2em;
                            text-transform: uppercase;
                            font-family: PoppinsBold;
                        }
                   }
                }

                @media only screen and (max-width:420px) {
                    flex-direction: column;
                }
            }

             @media only screen and (max-width:1024px) {
                position: relative;
                margin-bottom: 10px;
                max-width: 100%;
                padding: 0;
            }
            @media only screen and (max-width:420px) {
                flex-direction: column;
                
            }
        }
    }

    @media only screen and (max-width:1024px) {
        flex-direction: column;
    }
    @media only screen and (max-width:420px) {
        padding: 0;
    }
}

.plans-btn-sticky {
    color: white;
    text-transform: uppercase;
    background-color: var(--y-green1);
    cursor: pointer;
    font-family: PoppinsSemiBold;;
    padding: 10px 20px;
    display: block;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 60px;
    z-index: 10;
}

.premium-cta-wrapper {
    padding: 40px 30px;
    text-align: center;
}

.remove-account-btn {

    display: block;
    padding: 10px 0px;
    color: var(--y-green1);
    background-color: transparent;
    font-family: PoppinsRegular;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: .28571429rem;
    float: right;
    cursor: pointer;
}

.modify-email-correct {
    font-family: RobotoRegular;
    color: var(--grey3);
    font-size: 13px;
    font-style: italic;
    margin-top: 10px;
}

.right-form-block {
    position: absolute;
    padding-top: 23px;
    top: 0;
    right: 0;
    .icon-change-email {
        &:before {
            color: var(--y-green1)!important;
        }
        cursor: pointer;
        font-size: 24px;
    }
}

.left-form-block {
    // float: left;
    margin-top: 10px;
    .label-email {
        display:block;
        margin: 0 0 .28571429rem 0;
        color: rgba(0,0,0,.87);
        font-size: .92857143em;
        font-weight: 700;
        text-transform: none;
        font-size: 14px !important;
        line-height: 21px !important;
        text-align: left;
        color: var(--y-grey5) !important;
        white-space: pre;
    
    }
    .field-email {
        margin: 0 0 .28571429rem 0;
        color: rgba(0,0,0,.87);
        font-size: .92857143em;
        text-transform: none;
        font-size: 14px !important;
        line-height: 21px !important;
        text-align: left;
        color: var(--y-grey5) !important;
        color: var(--y-grey1) !important;
    }
}

.p-section-text {
    font-size: 22px;
    text-align: left;
    font-family: PoppinsSemiBold;
    color: var(--y-grey3);
    @media only screen and (max-width: 420px) {
        font-size: 18px;
    }
}

.w-container {
    padding: 0 30px;
    @media only screen and (max-width: 420px) {
        padding: 0 20px;
    }
}