
    .wrapper-shape {
        display: flex;
        width: 100%;
        
        .shape {
            flex: 1;
            height: 600px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                max-width: 100%;
                height: 100%;
            }

            &.not-slider {
                display: none;
                @media only screen and (min-width: 1150px) {
                    display: flex;
                }
            }
            
            @media only screen and (max-width:660px) {
                height: 450px;
            }
        }

        .slick-slider {
            text-align: center;
            width: 100%;
            overflow: hidden;
            grid-column: 1 / span 2;
            @media only screen and (min-width: 1150px) {
                display: none;
            }
        }
       
    }

